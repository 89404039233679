import { Fraction } from '@uniswap/sdk-core'
import { CurrencyAmount } from '@uniswap/sdk-core'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { MouseoverTooltip } from 'components/Tooltip'
import { useCurrency, useToken } from 'hooks/Tokens'
import { useTokenPriceCache } from 'hooks/useRPCTokenPriceFetcher'
import { floorFormatter } from 'nft/utils'
import { ToUSD } from 'pages/FarmV3/FarmDetails/details'
import { EarningAmounts } from 'state/farm/types'
import styled from 'styled-components'
import { ThemedText } from 'theme'
import { ToDecimalsExpandedOrDefault } from 'utils/currency'
import { formatNumber, NumberType } from 'utils/formatNumbers'

interface EarningsProps {
  earnings: EarningAmounts[]
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  border-radius: 12px;
  cursor: pointer;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px;
`

const EarningsText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.success};
  font-weight: bold;
  font-size: 1em;
`

const CurrencyContainer = styled.div`
  position: relative;
  display: inline-block;
`

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const EarningsRow: React.FC<{
  token: EarningAmounts
  priceMap: any
  isSingle: boolean
}> = ({ token, priceMap, isSingle }: { token: EarningAmounts; priceMap: any; isSingle: boolean }) => {
  const currency = useCurrency(token.address)
  const token1 = useToken(token.address)
  const tokenAmount = token1 ? CurrencyAmount.fromRawAmount(token1, token.earnings) : null
  // const amount = currency ? CurrencyAmount.fromRawAmount(currency, token.earnings) : null
  const decimals = ToDecimalsExpandedOrDefault(currency?.decimals)
  const normalizedEarningsDisplay = new Fraction(token.earnings, decimals).toFixed(2)
  const priceUsd = tokenAmount ? ToUSD(priceMap, tokenAmount) : 0

  return (
    <MouseoverTooltip
      text={
        <TooltipContent>
          <ThemedText.BodySecondary>Estimated daily earnings:</ThemedText.BodySecondary>
          <TooltipRow>
            <CurrencyLogo currency={currency} size="16px" />
            <ThemedText.BodyPrimary>
              {formatNumber({
                input: priceUsd,
                type: NumberType.FiatTokenPrice,
              })}
            </ThemedText.BodyPrimary>
          </TooltipRow>
        </TooltipContent>
      }
      placement="top"
    >
      <Row>
        <EarningsText>
          {floorFormatter(Number(normalizedEarningsDisplay))}
          {currency && <CurrencyLogo currency={currency} size="24px" />}
          {isSingle && ' / day'}
        </EarningsText>
      </Row>
    </MouseoverTooltip>
  )
}

const Earnings: React.FC<EarningsProps> = ({ earnings }: { earnings: EarningAmounts[] }) => {

  const priceMap = useTokenPriceCache()

  return (
    <Container>
      {earnings.map((token, index) => (
        <EarningsRow key={index} token={token} priceMap={priceMap} isSingle={earnings.length === 1 && index === 0} />
      ))}
    </Container>
  )
}

export default Earnings
